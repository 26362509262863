import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import * as yup from 'yup';
import { AnimatePresence, motion } from 'framer-motion';
import { heightCollapse } from 'utils/motion/height-collapse';

import AuthLayout from 'layouts/auth-layout/auth-layout';
import { signUpAPI } from 'utils/api';
import { getFieldProps } from 'utils';
import { passwordRe } from 'utils/constant';
import { useNotify } from 'contexts/notification.context';
import { AUTH_LOGIN_PAGE } from 'site-settings/site-navigation';
import { Input } from 'components/common/form/input';
import { PasswordInput } from 'components/password-input/password-input';
import { Button } from 'components/common/button/button';

import {
  FormField,
  FormFieldsWrapper,
  FormRow,
  Heading,
  InfoText,
  LabelError,
  LabelInput,
  LinkButton,
  Offer,
  OptionalText,
  Wrapper
} from 'assets/styles/form.style';
import SEO from '../../components/seo';

const initialValues = {
  userName: '',
  email: '',
  password: '',
  confirmPassword: '',
  terms: true
};

export default function SignOutFormPage() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [step, setStep] = useState('registerForm');
  const [loading, setLoading] = useState(false);
  const { setMessageError } = useNotify();

  const validate = {
    userName: yup
      .string()
      .required(t('authForm.registerFields.usernameRequired'))
      .min(4, t('authForm.registerFields.usernameMin'))
      .max(40, t('authForm.registerFields.usernameMax'))
      .matches(
        /^[a-zA-Z0-9-_]+$/,
        t('authForm.registerFields.usernameMatches')
      ),
    email: yup
      .string()
      .required(t('authForm.registerFields.emailRequired'))
      .email(t('authForm.registerFields.emailValid'))
      .max(40, t('authForm.registerFields.emailMax')),
    password: yup
      .string()
      .required(t('authForm.registerFields.passwordRequired'))
      .min(8, t('authForm.registerFields.passwordMin'))
      .max(32, t('authForm.registerFields.passwordMax'))
      .matches(passwordRe, t('authForm.registerFields.passwordMatches')),
    confirmPassword: yup.string().when('password', {
      is: (value) => value && value.length > 0,
      then: yup
        .string()
        .required(t('authForm.registerFields.passwordRequired'))
        .oneOf(
          [yup.ref('password'), null],
          t('authForm.registerFields.passwordMatch')
        )
    })
  };

  const registerCallback = async (
    values,
    { touched, validateForm, setTouched, setSubmitting, resetForm }
  ) => {
    const errs = await validateForm();
    const referrerCode = localStorage.getItem('referrerCode');

    if (isEmpty(errs)) {
      const modifyValues = { ...values };
      if (referrerCode) {
        modifyValues.referrerCode = referrerCode;
      }
      try {
        setLoading(true);
        await signUpAPI(modifyValues);
        setLoading(false);
        setSubmitting(false);
        setEmail(values.email);
        setStep('afterSubmit');
      } catch (err) {
        setLoading(false);
        setMessageError(err);
        resetForm();
      }
    } else {
      setTouched({ ...touched, ...errs });
    }
  };

  return (
    <AuthLayout>
      <Wrapper>
        {step === 'registerForm' && (
          <>
            <Heading>{t('authForm.registerText')}</Heading>
            <Formik
              validateOnBlur
              initialValues={initialValues}
              validationSchema={yup.object().shape(validate)}
              onSubmit={registerCallback}
            >
              {(formik) => {
                const {
                  isValid,
                  values,
                  handleChange,
                  handleBlur,
                  touched,
                  errors
                } = formik;
                return (
                  <Form noValidate>
                    <FormFieldsWrapper className="withMargin">
                      <FormRow>
                        <FormField>
                          <LabelInput htmlFor="userName">
                            {t('authForm.usernameLabel')}
                          </LabelInput>
                          <Input
                            type="text"
                            id="userName"
                            name="userName"
                            placeholder={t('authForm.usernamePlaceholder')}
                            {...getFieldProps(formik, 'userName')}
                            required
                          />
                          <AnimatePresence>
                            {Boolean(errors.userName) && touched.userName && (
                              <motion.div
                                initial="from"
                                animate="to"
                                exit="from"
                                variants={heightCollapse()}
                              >
                                <LabelError>{errors.userName}</LabelError>
                              </motion.div>
                            )}
                          </AnimatePresence>
                        </FormField>
                      </FormRow>
                      <FormRow>
                        <FormField>
                          <LabelInput htmlFor="email">
                            {t('authForm.emailLabel')}
                          </LabelInput>
                          <Input
                            id="email"
                            type="email"
                            name="email"
                            placeholder={t('authForm.emailPlaceholder')}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(errors.email) && touched.email}
                            required
                          />
                          <AnimatePresence>
                            {Boolean(errors.email) && touched.email && (
                              <motion.div
                                initial="from"
                                animate="to"
                                exit="from"
                                variants={heightCollapse()}
                              >
                                <LabelError>{errors.email}</LabelError>
                              </motion.div>
                            )}
                          </AnimatePresence>
                        </FormField>
                      </FormRow>
                      <FormRow>
                        <FormField>
                          <LabelInput htmlFor="password">
                            {t('authForm.passwordLabel')}
                          </LabelInput>
                          <PasswordInput
                            id="password"
                            name="password"
                            placeholder={t('authForm.passwordPlaceholder')}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(errors.password) && touched.password}
                            required
                          />
                          <AnimatePresence>
                            {Boolean(errors.password) && touched.password && (
                              <motion.div
                                initial="from"
                                animate="to"
                                exit="from"
                                variants={heightCollapse()}
                              >
                                <LabelError>{errors.password}</LabelError>
                              </motion.div>
                            )}
                          </AnimatePresence>
                        </FormField>
                      </FormRow>
                      <FormRow>
                        <FormField>
                          <LabelInput htmlFor="confirmPassword">
                            {t('authForm.confirmPassLabel')}
                          </LabelInput>
                          <PasswordInput
                            id="confirmPassword"
                            name="confirmPassword"
                            placeholder={t(
                              'authForm.confirmPasswordPlaceholder'
                            )}
                            value={values.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              Boolean(errors.confirmPassword) &&
                              touched.confirmPassword
                            }
                            required
                          />
                          <AnimatePresence>
                            {Boolean(errors.confirmPassword) &&
                              touched.confirmPassword && (
                                <motion.div
                                  initial="from"
                                  animate="to"
                                  exit="from"
                                  variants={heightCollapse()}
                                >
                                  <LabelError>
                                    {errors.confirmPassword}
                                  </LabelError>
                                </motion.div>
                              )}
                          </AnimatePresence>
                        </FormField>
                      </FormRow>
                    </FormFieldsWrapper>

                    <Button
                      loading={loading}
                      disabled={!isValid}
                      type="submit"
                      fullwidth
                    >
                      {t('authForm.createAccountText')}
                    </Button>

                    {/* <Offer align="center">{t('authForm.registerWithText')}</Offer> */}
                  </Form>
                );
              }}
            </Formik>
            {/* <With>
              <Button variant="light" icon={<GoogleIcon />} fullwidth>
                {t('authForm.loginWithGoogleText')}
              </Button>
              <Button variant="light" icon={<FacebookIcon />} fullwidth>
                {t('authForm.loginWithFacebookText')}
              </Button>
            </With> */}
            <Offer align="center">
              {t('authForm.alreadyHaveAccountText')}{' '}
              <LinkButton to={AUTH_LOGIN_PAGE}>
                {t('authForm.loginText')}
              </LinkButton>
            </Offer>
          </>
        )}
        {step === 'afterSubmit' && (
          <>
            <Heading>{t('authForm.registerText')}</Heading>
            <FormRow>
              <OptionalText>
                {t('authForm.registerFields.instructions1')}
              </OptionalText>
            </FormRow>
            <FormRow>
              <Input
                type="email"
                name="email"
                readonly
                placeholder={t('authForm.emailPlaceholder')}
                value={email}
              />
            </FormRow>
            <FormRow className="ext">
              <OptionalText>
                {t('authForm.registerFields.instructions2')}
              </OptionalText>
            </FormRow>
            <FormRow>
              <InfoText>{t('authForm.registerFields.instructions3')}</InfoText>
            </FormRow>
          </>
        )}
      </Wrapper>
    </AuthLayout>
  );
}

export const Head = () => {
  const { t } = useTranslation();
  return <SEO title={t('authForm.registerText')} />;
};
